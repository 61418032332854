import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { FormControl, FormGroup, Overlay, Popover } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { StatesList } from '@core/enums/USStates';
import { OPERATORS, getOperators } from '@core/models/Operator';
import { ValueType } from '@core/models/Variable';
import VariableFilter, { DYNAMIC_DATES } from '@core/models/VariableFilter';
import { DateFormatter } from '@core/utils/DateTime';

import { Alert, Button, Checkbox, DayTime, Dropdown, Form, Icon, MenuItem } from '@components/dmp';

@autoBindMethods
export default class VariableFilterView extends Component {
  static propTypes = {
    variable: PropTypes.object.isRequired,
    history: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    // isRequired needs to be removed here because the ref value is null on initial render
    // but it's populated by the time user clicks to open config (where container is used)
    container: PropTypes.object,
    filter: PropTypes.object,
    configOnly: PropTypes.bool,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    configOnly: false,
    disabled: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      configuring: false,
      operatorName: null,
      value1: '',
      value2: '',
      options: [],
    };
  }

  componentDidMount() {
    this.loadFilter(this.props.filter);
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.filter, this.props.filter)) {
      this.loadFilter(this.props.filter);
    }
  }

  get operator() {
    return _.get(OPERATORS, this.state.operatorName, null);
  }

  get canApply() {
    const { variable } = this.props;
    const { value1, value2, options } = this.state;

    // Don't allow empty/invalid filters to be applied
    let canApply = true;
    if (!this.operator) {
      canApply = false;
    } else if (this.operator.valueCount === 1 || this.operator.key === OPERATORS.DYNAMIC.key) {
      canApply = !!value1;
    } else if (this.operator.valueCount === 2) {
      canApply = !!value1 && !!value2;
    } else if (variable.valueType === ValueType.LIST) {
      canApply = !!options.length;
    }

    return canApply;
  }

  loadFilter(filter) {
    if (!filter) return;

    const valueType = _.get(this.props, 'variable.valueType');
    const state = { operatorName: _.get(filter, 'operator.name', null) };

    // Only allow options to be selected for LIST/STATE vars
    if ([ValueType.LIST, ValueType.STATE, ValueType.MULTI_SELECT].indexOf(valueType) > -1) {
      state.options = _.get(filter, 'values', []);
    }
    // DATE vars are stored numerically (seconds) so we need to convert to/from that
    else if (valueType === ValueType.DATE) {
      const date1 = _.get(filter, 'values[0]', null);
      const date2 = _.get(filter, 'values[1]', null);

      // But dynamic dates just use the value as number of days so don't convert those
      if (filter.operator.key === OPERATORS.DYNAMIC.key) {
        if (date1) state.value1 = date1;
      } else {
        //get time offset in miliseconds
        if (date1)
          state.value1 = DateFormatter.mdy(new Date(date1 * 1000 + DateFormatter.getOffset(new Date(date1 * 1000))));
        if (date2)
          state.value2 = DateFormatter.mdy(new Date(date2 * 1000 + DateFormatter.getOffset(new Date(date2 * 1000))));
      }
    } else {
      state.value1 = _.get(filter, 'values[0]', '');
      state.value2 = _.get(filter, 'values[1]', '');
    }

    this.setState(state);
  }

  configureFilter() {
    const { variable } = this.props;
    let operators = getOperators(variable.valueType);

    if (variable.isRedacted) {
      operators = operators.filter((o) => [OPERATORS.KNOWN.key, OPERATORS.UNKNOWN.key].includes(o.key));
    }

    this.setState({
      configuring: true,
      operator: this.operator || operators[0] || null,
    });
  }

  // Changing to an operator with fewer required values
  // should clear those values in state, to be safe
  async selectOperator(operatorName) {
    const { configOnly, onChange, variable } = this.props;
    const state = { operatorName };
    const operator = _.get(OPERATORS, operatorName, null);
    let triggerChange = false;

    if (operator) {
      if (operator.valueCount < 2) state.value2 = '';
      if (operator.valueCount < 1) state.value1 = '';

      if (variable.valueType === ValueType.DATE) {
        if (this.state.operatorName === OPERATORS.DYNAMIC.name || !this.state.value1) {
          //If coming from DYNAMIC (with values like 7) or we don't have a date already, set value to today to avoid something like new Date(7)
          state.value1 = new Date();
          state.value2 = '';
          triggerChange = true;
        }

        if (this.state.operatorName !== OPERATORS.DYNAMIC.name && operator.name === OPERATORS.DYNAMIC.name) {
          //If going to DYNAMIC
          state.value1 = DYNAMIC_DATES[0].val;
          triggerChange = true;
        }
      }
    }

    await this.setState(state);
    if (configOnly || triggerChange) onChange(this.currentFilter);
  }

  toggleOption(option) {
    const { configOnly, onChange } = this.props;
    const { options } = this.state;

    //add or remove the passed-in option to the condition's current set of allowable values, which is in state
    const idx = options.indexOf(option);
    if (idx > -1) options.splice(idx, 1);
    else options.push(option);

    this.setState({ options });

    if (configOnly) onChange(this.currentFilter);
  }

  saveFilter() {
    const { onChange } = this.props;

    onChange(this.currentFilter);
    this.setState({ configuring: false });
  }

  get currentFilter() {
    const { value1, value2, options } = this.state;
    const { variable } = this.props;

    let values = [];
    if ([ValueType.LIST, ValueType.STATE, ValueType.MULTI_SELECT].indexOf(variable.valueType) > -1) {
      values = options;
    }
    // Convert dates to seconds, which is what's used for comparison
    // Unless we have dynamic dates, or known/unknown, in which case we're just passing in a number of days away or nullish
    // And the date comparison is done server-side
    else if (
      variable.valueType === ValueType.DATE &&
      ![OPERATORS.DYNAMIC.key, OPERATORS.KNOWN.key, OPERATORS.UNKNOWN.key].includes(this.operator.key)
    ) {
      let dateFilterFormatted;

      if (value1) {
        //Get the date inputted in the iso format YYYY-MM-DD This givs us the stored value correctly
        dateFilterFormatted = DateFormatter.buildESDateFormat(value1);
        values.push(Date.parse(dateFilterFormatted) / 1000);
      }
      if (value2) {
        //Get the date inputted in the iso format YYYY-MM-DD This givs us the stored value correctly
        dateFilterFormatted = DateFormatter.buildESDateFormat(value2);
        values.push(Date.parse(dateFilterFormatted) / 1000);
      }
    } else {
      if (value1) values.push(value1);
      if (value2) values.push(value2);
    }

    return new VariableFilter(variable.name, {
      valueType: variable.valueType,
      operator: this.operator.key,
      values,
    });
  }

  async clearFilter() {
    const { onChange, variable, filter } = this.props;

    // Clear state and hide popover
    await this.setState({
      configuring: false,
      operatorName: null,
      value1: '',
      value2: '',
      options: [],
    });

    // We only need to call onChange with an empty filter if one exists already,
    // which will cause this component to re-render
    if (filter) {
      const emptyFilter = new VariableFilter(variable.name, null);
      onChange(emptyFilter);
    }
  }

  // If we're in configOnly mode, we want to automatically communicate local state changes back to parent
  // This way this component can be used to delegate config of a VariableFilter as part of another model,
  // without needing to explicitly "Apply" or "Save" changes of the VariableFilter alone
  async localChange(property, value) {
    await this.setState({ [property]: value });

    this.handleChange();
  }

  //Only update the text once the user unfocuses on it if changes were made.
  //This prevents in Condition editor saving on each character inputted
  handleChange = () => {
    const { configOnly, onChange, filter } = this.props;

    if (configOnly && !_.isEqual(filter.json, this.currentFilter.json)) onChange(this.currentFilter);
  };

  render() {
    const { variable, filter, configOnly } = this.props;
    const { configuring } = this.state;

    return (
      <div className="variable-filter" ref="filterAnchor" data-cy="variable-filter">
        {!configOnly && (
          <div className={cx('filter-name', { active: !!filter })} onClick={this.configureFilter}>
            <div>{variable.displayName || variable.name}</div>
            <Icon name="filterExpand" />
          </div>
        )}

        {(configuring || configOnly) && this.renderConfig()}

        {filter && !configOnly && (
          <div className="existing" onClick={this.configureFilter}>
            <div className="filter-display" data-cy="filter-display">
              {filter.displayLabel}
            </div>
          </div>
        )}
      </div>
    );
  }

  renderConfig() {
    const { variable, container, template, configOnly, disabled } = this.props;
    const { value1 } = this.state;
    const operatorKey = _.get(this, 'operator.key');

    let operators = getOperators(variable.valueType);

    if (variable.isRedacted) {
      operators = operators.filter((o) => [OPERATORS.KNOWN.key, OPERATORS.UNKNOWN.key].includes(o.key));
    }

    const title = this.operator && !operators.some((o) => o.key === operatorKey) ? ' (removed)' : '';

    const configUI = (
      <>
        {operators.length > 0 && (
          <FormGroup>
            <Dropdown
              id={`dd-operators-${variable.name}`}
              title={`${_.get(this.operator, 'title', 'Select one')} ${title}`}
              size="small"
              onSelect={this.selectOperator}
              block
              disabled={disabled}
            >
              {operators.map((operator) => (
                <MenuItem key={operator.name} eventKey={operator.name}>
                  {operator.title}
                </MenuItem>
              ))}
            </Dropdown>
          </FormGroup>
        )}

        {variable.isInferred && template && (
          <Alert size="small" dmpStyle="danger">
            To enable filtering on this variable, edit it's{' '}
            <Link to={`/templates/${template.dealID}/editor`}>template</Link>, find an instance of the{' '}
            {variable.rawText} variable and tap [update].
          </Alert>
        )}

        {this.renderValues()}

        {operatorKey === OPERATORS.DYNAMIC.key && (
          <FormGroup>
            <Dropdown
              id={`dd-dynamic-${variable.name}`}
              title={_.get(_.find(DYNAMIC_DATES, { val: parseInt(value1) }), 'title', 'Select one')}
              size="small"
              onSelect={(dateOption) => this.localChange('value1', dateOption.val)}
              block
            >
              {DYNAMIC_DATES.map((dateOption, idx) => (
                <MenuItem key={idx} eventKey={dateOption}>
                  {dateOption.title}
                </MenuItem>
              ))}
            </Dropdown>
          </FormGroup>
        )}

        {(variable.valueType === ValueType.LIST || variable.valueType === ValueType.MULTI_SELECT) &&
          ![OPERATORS.KNOWN, OPERATORS.UNKNOWN].includes(this.operator) && (
            <div className="variable-options scrollable">{variable.options.map(this.renderListOption)}</div>
          )}
        {variable.valueType === ValueType.STATE && ![OPERATORS.KNOWN, OPERATORS.UNKNOWN].includes(this.operator) && (
          <div className="variable-options state scrollable">{StatesList.map(this.renderStateOption)}</div>
        )}

        {!configOnly && (
          <div className="actions">
            <Button size="small" dmpStyle="link" onClick={this.clearFilter} data-cy="btn-clear-filter">
              Clear
            </Button>
            <Button
              size="small"
              dmpStyle="primary"
              onClick={this.saveFilter}
              disabled={!this.canApply}
              data-cy="btn-apply-filter"
            >
              Apply
            </Button>
          </div>
        )}
      </>
    );

    if (configOnly) {
      return configUI;
    } else {
      return (
        <Overlay
          show={true}
          onHide={() => this.setState({ configuring: false })}
          target={this.refs.filterAnchor}
          placement="right"
          container={container}
          rootClose
        >
          <Popover
            className="variable-filter popover-deal-var-filter"
            id={`pop-var-filter-${variable.name}`}
            title={variable.displayName || variable.name}
          >
            <Form className="configuring">{configUI}</Form>
          </Popover>
        </Overlay>
      );
    }
  }

  renderValues() {
    const { variable, disabled } = this.props;
    const { value1, value2 } = this.state;

    const valueCount = _.get(this.operator, 'valueCount');

    if (!(valueCount > 0)) return null;

    if (variable.valueType === ValueType.DATE)
      return (
        <div className={cx('values', { pair: valueCount === 2 })}>
          <DayTime
            type="text"
            bsSize="small"
            onChange={(val, stuff) => {
              const { date, dateTo } = val;
              if (date) this.localChange('value1', date);
              if (dateTo) this.localChange('value2', dateTo);
            }}
            hasDateTo={this.operator === OPERATORS.BETWEEN ? true : false}
            date={value1 ? new Date(value1) : null}
            dateTo={value2 ? new Date(value2) : null}
            readOnly={false}
            hideOnClick={true}
          />
        </div>
      );

    return (
      <div className={cx('values', { pair: valueCount === 2 })}>
        <FormControl
          type="text"
          bsSize="small"
          value={value1}
          onChange={(e) => this.localChange('value1', e.target.value)}
          onBlur={this.handleChange}
          disabled={disabled}
        />
        {valueCount === 2 && <div className="and">and</div>}
        {valueCount === 2 && (
          <FormControl
            type="text"
            bsSize="small"
            value={value2}
            disabled={disabled}
            onChange={(e) => this.localChange('value2', e.target.value)}
            onBlur={this.handleChange}
          />
        )}
      </div>
    );
  }

  renderListOption(option, index) {
    const { variable, disabled } = this.props;
    const { options } = this.state;

    return (
      <Checkbox
        id={`check-opt-${variable.name}-${index}`}
        key={index}
        checked={options.indexOf(option?.key || option?.value) > -1}
        onChange={() => this.toggleOption(option?.key || option?.value)}
        disabled={!this.operator || disabled}
      >
        {option?.label || option?.title}
      </Checkbox>
    );
  }

  renderStateOption(option, index) {
    const { disabled } = this.props;
    const { options } = this.state;

    return (
      <Checkbox
        id={`check-state-${option.key}-${index}`}
        key={index}
        checked={options.indexOf(option.key) > -1}
        onChange={() => this.toggleOption(option.key)}
        disabled={disabled}
      >
        {option.name}
      </Checkbox>
    );
  }
}
