import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { FormGroup, Overlay, Popover } from 'react-bootstrap';

import { OPERATORS, getOperators } from '@core/models/Operator';
import { DYNAMIC_DATES } from '@core/models/VariableFilter';

import { Button, DayTime, Dropdown, Form, Icon, MenuItem } from '@components/dmp';

@autoBindMethods
export default class DateFilterView extends Component {
  static propTypes = {
    dateFilterName: PropTypes.string.isRequired,
    date: PropTypes.string,
    dateTo: PropTypes.string,
    filter: PropTypes.object,
    title: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      showPopup: false,
      date: null,
      dateTo: null,
      dynamicDates: null,
      operatorName: null,
      disableFilter: true,
    };

    this.filterAnchor = React.createRef();
  }

  componentDidMount() {
    const { date, dateTo, dynamicDates, filter } = this.props;

    this.setState({
      date: date ? new Date(date) : new Date(),
      dateTo: dateTo ? new Date(dateTo) : null,
      dynamicDates: dynamicDates ? dynamicDates : null,
      operatorName: filter ? filter.o : null,
    });
  }

  componentDidUpdate(prevProps) {
    const { date, dateTo, dynamicDates, filter } = this.props;

    if (prevProps.date !== date) this.setState({ date: date ? new Date(date) : new Date() });
    if (prevProps.dateTo !== dateTo) this.setState({ dateTo: dateTo ? new Date(dateTo) : null });
    if (prevProps.dynamicDates !== dynamicDates) this.setState({ dynamicDates: dynamicDates ? dynamicDates : null });
    if (prevProps.filter !== filter) this.setState({ operatorName: filter ? filter.o : null });
  }

  configureFilter() {
    this.setState({ showPopup: true });
  }

  updateDate(dateRange) {
    const newState = {};

    if (dateRange.date) {
      newState.date = dateRange.date;
    }
    if (dateRange.dateTo) {
      newState.dateTo = dateRange.dateTo;
    }

    this.setState(newState);
  }

  setDynamic(dateOption) {
    this.setState({ dynamicDates: dateOption, disableFilter: false });
  }

  saveFilter() {
    const { onChange, dateFilterName } = this.props;
    const { date, dateTo, operatorName, dynamicDates } = this.state;

    //if we are using isEqual only send the first date through
    if (this.operator.name === OPERATORS.EQUAL.name) {
      onChange(date, null, dateFilterName, operatorName);
    } else if (this.operator.name === OPERATORS.DYNAMIC.name) {
      // Dyanmic range is only for last n days
      // Dynamic dates are actually *between* last n date and current date
      const currentDate = new Date();
      const dynamicDate = new Date(new Date().setDate(currentDate.getDate() + dynamicDates.val + 1));

      onChange(dynamicDate, currentDate, dateFilterName, operatorName, dynamicDates.title);
    } else {
      onChange(date, dateTo, dateFilterName, operatorName);
    }

    this.setState({ showPopup: false, disableFilter: true });
  }

  clearFilter() {
    const { onChange, dateFilterName } = this.props;
    this.setState({
      showPopup: false,
      date: null,
      dateTo: null,
      dynamicDates: null,
      operatorName: null,
      disableFilter: true,
    });

    onChange(null, null, dateFilterName, null);
  }

  get operator() {
    const { operatorName } = this.state;
    return _.get(OPERATORS, operatorName, null);
  }

  render() {
    const { title, filter } = this.props;
    const { showPopup, dynamicDates } = this.state;
    const operator = this.operator;

    return (
      <div className="variable-filter" ref={this.filterAnchor}>
        <div className={cx('filter-name', { active: !!filter })} onClick={this.configureFilter}>
          <div>{title}</div>
          <Icon name="filterExpand" />
        </div>

        {showPopup && this.renderConfig()}

        {filter && operator && (
          <div className="existing" onClick={this.configureFilter}>
            <div className="filter-display" data-cy="filter-display">
              {operator.name === OPERATORS.DYNAMIC.name
                ? `${filter.filterLabel}`
                : `${operator.title}: ${filter.filterLabel}`}
            </div>
          </div>
        )}
      </div>
    );
  }

  renderConfig() {
    const { title } = this.props;
    const { date, dateTo, disableFilter, dynamicDates } = this.state;
    const operators = getOperators('DateRange');

    return (
      <Overlay
        show={true}
        onHide={() => this.setState({ showPopup: false, disableFilter: true })}
        target={this.filterAnchor.current}
        placement="right"
        rootClose
      >
        <Popover className="date-filter popover-deal-date-filter" id={`pop-date-filter-${title}`} title={title}>
          <Form className="configuring">
            <FormGroup>
              <Dropdown
                id={`dd-operators-${title}`}
                title={_.get(this.operator, 'title', 'Select one')}
                size="small"
                onSelect={(o) =>
                  this.setState({
                    operatorName: o,
                    disableFilter: o === OPERATORS.DYNAMIC.name && !dynamicDates ? true : false,
                  })
                }
                block
              >
                {operators.map((operator) => (
                  <MenuItem key={operator.name} eventKey={operator.name}>
                    {operator.title}
                  </MenuItem>
                ))}
              </Dropdown>
            </FormGroup>
            {this.operator && this.operator === OPERATORS.DYNAMIC && (
              <FormGroup>
                <Dropdown
                  id={`dd-dynamic-`}
                  title={dynamicDates ? dynamicDates.title : 'Select one'}
                  size="small"
                  onSelect={this.setDynamic}
                  block
                >
                  {/* Only showing the dropdwon options for last n days */}
                  {DYNAMIC_DATES.slice(4).map((dateOption, idx) => (
                    <MenuItem key={idx} eventKey={dateOption}>
                      {dateOption.title}
                    </MenuItem>
                  ))}
                </Dropdown>
              </FormGroup>
            )}
            {this.operator && this.operator !== OPERATORS.DYNAMIC && (
              <FormGroup>
                <DayTime
                  disabled={false}
                  hasDateTo={this.operator === OPERATORS.BETWEEN ? true : false}
                  onChange={this.updateDate}
                  date={date}
                  dateTo={dateTo}
                  readOnly={false}
                  hideOnClick={true}
                  disableFilter={(value) => {
                    this.setState({ disableFilter: value });
                  }}
                />
              </FormGroup>
            )}
            <div className="actions">
              <Button size="small" dmpStyle="link" onClick={this.clearFilter} data-cy="btn-clear-date-filter">
                Clear
              </Button>
              <Button
                size="small"
                dmpStyle="primary"
                onClick={this.saveFilter}
                disabled={disableFilter}
                data-cy="btn-apply-date-filter"
              >
                Apply
              </Button>
            </div>
          </Form>
        </Popover>
      </Overlay>
    );
  }
}
